<template>
    <div id="accordion" v-if="topicData.length > 0">        
        <div class="card" v-for="(item, indx) of topicData" :key="indx">
            <div class="card-header" :id="`headingOne_${indx}`">
                <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" :data-target="`#collapseOne_${indx}`" aria-expanded="true" :aria-controls="`collapseOne_${indx}`">
                    {{ item.topics.title }}
                    </button>
                </h5>
            </div>

            <div :id="`collapseOne_${indx}`" class="collapse" :class="indx < 1 ? 'show' : ''" :aria-labelledby="`headingOne_${indx}`" data-parent="#accordion">
                <ul class="course-curriculum-list" v-for="(video, k) of item.video" :key="k">
                    <li> {{ video.title }} <span>  {{ video.duration }} </span></li>
                </ul>
            </div>
        </div>       
    </div>
</template>

<script>
export default {
    props: {
        topics: Array
    },
    data(){
        return {
            topicData: []
        }
    },
    created: function() {
        this.topicData = this.$props.topics
    }
}
</script>