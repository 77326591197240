<template>
    <form class="uk-grid-small uk-grid" id="reviews-frm" method="post" @submit.prevent="reviewFormSubmit" autocomplete="off">
        <div class="uk-width-1-2@s" id="name_block">
            <label class="uk-form-label">{{ fields('name') }}</label>
            <input class="uk-input" type="text" placeholder="Name" v-model="formData.name" disabled="disabled"  >
            <p class="error-msg d-none"></p>
        </div>
        <div class="uk-width-1-2@s" id="email_block">
            <label class="uk-form-label">{{ fields('email') }}</label>
            <input class="uk-input" type="email" placeholder="Email" v-model="formData.email" disabled="disabled" >
            <p class="error-msg d-none"></p>
        </div>
        <div class="uk-width-1-2@s my-1" id="rating_block">
            <label class="uk-form-label">{{ fields('rating') }}</label>
            <div class="rating">
                <div class="rate clearfix">
                    <input type="radio" id="star5" v-model="formData.rating" value="5">
                    <label for="star5" title="text">5 stars</label>
                    <input type="radio" id="star4" v-model="formData.rating" value="4">
                    <label for="star4" title="text">4 stars</label>
                    <input type="radio" id="star3" v-model="formData.rating" value="3">
                    <label for="star3" title="text">3 stars</label>
                    <input type="radio" id="star2" v-model="formData.rating" value="2">
                    <label for="star2" title="text">2 stars</label>
                    <input type="radio" id="star1" v-model="formData.rating" value="1">
                    <label for="star1" title="text">1 star</label>
                </div>
                <p class="form-error uk-width-1-1@s" v-if="!formStatus && $v.formData.rating.$error && !$v.formData.rating.required">{{ validationRequired('rating.required') }}</p>
            </div>
        </div>
        <div class="uk-width-1-1@s" id="comment_block">
            <label class="uk-form-label">{{ fields('comment') }}</label>
            <textarea class="uk-textarea" :placeholder="placeholderText('comment')" v-model.trim="$v.formData.comment.$model" style=" height:160px"></textarea>
            <p class="form-error" v-if="!formStatus && $v.formData.comment.$error && !$v.formData.comment.required">{{ validationRequired('comment.required') }}</p>
        </div>
        <div class="uk-grid-margin">
            <button type="submit" class="btn btn-default" data-title="Submit">{{ button }}</button>
        </div>
        <div class="resMssg col-sm-12" v-html="formResponse"></div>
    </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'

export default {
    props:{
        courseId: Number
    },
    data() {
        return {
            formData: {
                name: this.$store.state.auth.name,
                email: this.$store.state.auth.email,
                comment: '',
                rating: '',
                course_id: 0
            },
            formResponse: '',
            formStatus: true,
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    validations: {
        formData: {
            comment: {required},
            rating: {required},
        }
    }, 
    computed: {
        button() {
            return Translation.button[this.defaultLang].submit
        }
    },
    created: function(){
        this.formData.course_id = this.$props.courseId
    }, 
    methods: {
        placeholderText(field){
            return Translation.placeholder[this.defaultLang][field]
        },
        fields(field){
            return Translation.fields[this.defaultLang][field]
        },
        validationRequired(field){
            return Translation.validation[this.defaultLang][field]
        },
        reviewFormSubmit(){
            this.formStatus = false
            this.$v.$touch()
            if(!this.$v.$invalid){      
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = ''
                this.$http.post('course/review', this.formData)
                    .then(res=>{
                        if(res.data.status === true){
                            this.resMsg = res.data.message;
                            this.formResponse =`<p class="alert alert-success">${res.data.message}</p>`  
                            this.resetForm()
                        }
                        else {
                            this.formResponse =`<p class="alert alert-danger">${res.data.error}</p>`
                        }
                    }).catch(err=>{
                        if(err.response.status === 500)
                            this.formResponse =`<p class="alert alert-danger">Internal Server Error!</p>`
                        else
                            this.formResponse =`<p class="alert alert-danger">${err.response.data.error}</p>`
                    })

                this.togellingResponse();
            }
        },
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        },
        resetForm(){
            Object.keys(this.formData).forEach(item=>{
                this.formData[item] = ''
            })    
            this.formStatus = true
        }
    },
}
</script>