<template>
    <ul v-if="ratingData.length > 0">
        <li v-for="(item, indx) of ratingData" :key="indx">
            <!-- <div class="comments-avatar">
                <img src="/images/avatar.jpg" alt="Soumen Kamila">
            </div> -->
            <div class="comment-author-img">
                <span>{{ item.name.substring(0, 1).toUpperCase() }}</span>
            </div>
            <div class="comment-content">
                <div class="comment-by">
                    {{ item.name }}
                    <!-- <span>Student</span> -->
                    <div class="comment-stars">
                        <div class="star-rating">
                            <RatingStar :avgRating="parseInt(item.rating)" />
                        </div>
                    </div>
                </div>
                <div v-html="item.comment"></div>
                <!-- <div class="comment-footer">
                    <span> Was this review helpful? </span>
                    <button> Yes </button>
                    <button> No </button>
                    <a href="#"> Report</a>
                </div> -->
            </div>
        </li>
    </ul>
</template>

<script>
import RatingStar from '../Course/RatingStar'

export default {
    components: {RatingStar},
    props: {
        ratings: Array
    },
    data() {
        return {
            ratingData: []
        }
    },
    created: function () {
        this.ratingData = this.$props.ratings
    }
}
</script>