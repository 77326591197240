<template>
    <fragment>
        <div class="page-content" v-if="courseData.id">
            <div class="course-details-wrapper topic-1 uk-light pt-5">
                <div class="container p-sm-0">
                    <div class="uk-grid uk-grid-stack">
                        <div class="uk-width-2-3@m uk-first-column">
                            <div class="course-details">
                                <h1>{{ courseData.title }}</h1>
                                <div v-html="courseData.short_content"></div>
                                <div class="course-details-info mt-4">
                                    <ul>
                                        <li>
                                            <div class="star-rating">
                                                <span class="avg" v-if="avgRatings > 0">{{ avgRatings }}</span> 
                                                <span class="star" v-for="index in avgRatings" :key="index" ></span> 
                                                <template v-if="avgRatings < 5">      
                                                    <span class="star empty" v-for="n in (5 - avgRatings)" :key="n+'_r'"></span>     
                                                </template>    
                                            </div>
                                        </li>
                                        <!-- <li> <i class="icon-feather-users"></i> 1200 Enerolled </li> -->
                                    </ul>
                                </div>
                            </div>
                            <nav class="responsive-tab style-5">
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="tab" href="#overview">{{ pageContent('overview') }}</a>
                                    </li>
                                    <li class="nav-item" v-if="topicData.length > 0">
                                        <a class="nav-link" data-toggle="tab" href="#curriculum">{{ pageContent('curriculum') }}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#reviews">{{ pageContent('reviews') }}</a>
                                    </li>
                                    <li class="nav-item" v-if="assignemntData.length > 0">
                                        <a class="nav-link" data-toggle="tab" href="#assignment">{{ pageContent('assignment') }}</a>
                                    </li>
                                    <li class="nav-item" v-if="tutorData">
                                        <a class="nav-link" data-toggle="tab" href="#tutorProfile">{{ pageContent('tutor_profile') }}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>                
                </div>
            </div>
            <div class="container">
                <div class="uk-grid-large mt-4 uk-grid " >
                    <div class="uk-width-2-3@m uk-first-column">
                        <div class="tab-content">
                            <div id="overview" class="container tab-pane active">
                                <h4 v-if="courseData.content">{{ pageContent('description') }}</h4>
                                <div v-if="courseData.content" v-html="courseData.content"></div>

                                <h4 v-if="courseData.what_you_will_learn">{{ pageContent('what_you_will_learn') }}</h4>
                                <div v-if="courseData.what_you_will_learn" v-html="courseData.what_you_will_learn"></div>

                                <h4 v-if="courseData.requirements">{{ pageContent('requirements') }}</h4>
                                <div v-if="courseData.requirements" v-html="courseData.requirements"></div>
                            </div>
                            <div id="curriculum" class="container tab-pane fade" v-if="topicData.length > 0">
                                <Curriculum :topics="topicData"/>                          
                            </div>
                            <div id="reviews" class="container tab-pane fade">
                                <!-- <div class="review-summary">
                                    <h4 class="review-summary-title"> {{ pageContent('student_feedback') }} </h4>
                                    <div class="review-summary-container">
                                        <div class="review-summary-avg">
                                            <div class="avg-number">
                                                {{ avgRatings }}
                                            </div>
                                            <div class="review-star">
                                                <div class="star-rating">
                                                    <RatingStar :avgRating="avgRatings" />
                                                </div>
                                            </div>
                                            <span>{{ pageContent('course_rating') }}</span>
                                        </div>
                                        <ReviewSummary :reviewSummary="ratings" />
                                    </div>
                                </div> -->
                                <div class="comments" v-if="ratings.length > 0">
                                    <h4>{{ pageContent('reviews') }} <span class="comments-amount"> ({{ ratings.length }}) </span> </h4>
                                    <RatingList :ratings="ratings" />
                                </div>
                                <div class="comments comment-frm">
                                    <h3>{{ pageContent('submit_review') }}</h3>
                                    <ul>
                                        <li>
                                            <div class="comment-content">
                                                <RatingForm :courseId="courseId" />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div id="assignment" class="container tab-pane fade" v-if="assignemntData.length > 0">                                
                                <div class="accordion" id="assignmentLists">
                                    <div class="card" v-for="(item, indx) of assignemntData" :key="indx">
                                        <div class="card-header" :id="`heading_${indx}`">
                                            <h2 class="mb-0">
                                                <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="`#collapse_${indx}`" aria-expanded="true" :aria-controls="`collapse_${indx}`">
                                                {{ item.title }}
                                                </button>
                                            </h2>
                                        </div>

                                        <div :id="`collapse_${indx}`" class="collapse" :aria-labelledby="`heading_${indx}`" data-parent="#assignmentLists">
                                            <div class="card-body inlinebtn">
                                                <a :href="assignmentPath+'/assignment/'+item.file_name" target="download" class="btn-sm btn-default" title="Download Assignemnt">
                                                    <i class="icon-feather-download"></i> {{ button('download_assignment') }}
                                                </a>
                                                <div v-if="!item.user_copy">
                                                    <a href="#" @click.prevent="openUploadWindow(item.id)" class="btn-sm btn-default" title="Upload">
                                                        <i class="icon-feather-download"></i> {{ button('upload') }}
                                                    </a>
                                                    <input type="file" :id="`file_upload_${item.id}`" :data-id="item.id" name="assignment_solved_copy" class="d-none" @change="fileUpload($event)" />
                                                </div>
                                                <div v-else>
                                                    <a :href="assignmentPath+'/userassigment/'+item.user_copy" target="download" class="btn-sm btn-default-outline" :title="button('preview')">
                                                        <i class="icon-feather-download"></i> {{ button('preview') }}
                                                    </a>
                                                    <a href="#" @click.prevent="openUploadWindow(item.id)" target="download" class="btn-sm btn-default" :title="button('delete_and_resubmit')">
                                                        <i class="icon-feather-upload"></i> {{ button('delete_and_resubmit') }}
                                                    </a>
                                                    <input type="file" :id="`file_upload_${item.id}`" :data-id="item.id" name="assignment_solved_copy" class="d-none" @change="fileUpload($event)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tutorProfile" class="container tab-pane fade" v-if="tutorData">    
                                <div class="card">
                                    <div class="card-body">
                                        <img data-sizes="auto" class="img-thumbnail tutor-profile-img" :src="`${assignmentPath}/user/thumb/${tutorData.image_name}`" :alt="tutorData.username" :title="tutorData.username" >
                                        <h3 class="heading wow fadeInUp" >{{ tutorData.username }}</h3>
                                        <p v-html="tutorData.yourself"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-3@m cd_right">
                        <div class="course-card-trailer uk-sticky uk-active uk-sticky-fixed" uk-sticky="top: 10 ;offset:95 ; media: @m  ; bottom:true" style="position: fixed; top: 95px; width: 373px;">
                            <div class="course-thumbnail">
                                <img v-if="dirPath" :src="dirPath" alt="" />
                                <img v-else src="/images/no_image.jpg">
                            </div>
                            <div class="p-3">                            
                            <div class="uk-child-width-1-2 uk-grid-small mb-4 uk-grid" uk-grid="">
                                <div class="uk-first-column">
                                    <router-link :to="(totalVideos > 0) ? `/user/course/${courseId}/video` : `#`" class="uk-width-1-1 btn btn-default transition-3d-hover"> <i class="uil-play"></i> {{ button('start') }} </router-link>
                                </div>
                                <div class="uk-first-column">
                                    <a href="https://chat.howofwhat.com/login" target="_blank" class="uk-width-1-1 btn btn-default-outline transition-3d-hover"><i class="uil uil-chat"></i> {{ button('chat') }}</a>
                                </div>
                            </div>
                            
                            <div class="wishlist_section">
                                <a href="javascript:void(0)" class="favourite-btn btn btn-danger transition-3d-hover" >
                                    <i class="favourite" 
                                        @click="addToFavourite(courseData.id, $event)"
                                        :class="(courseData.is_favourite) ? `icon-material-outline-favorite` : `icon-material-outline-favorite-border`"
                                    ></i>
                                </a>
                            </div>
                            <p class="uk-text-bold"> {{ pageContent('this_course_include') }} </p>
                            <div class="uk-child-width-1-2 uk-grid-small uk-grid" uk-grid="">
                                <!-- <div>
                                    <span> <i class="uil-award"></i> {{ pageContent('certificate') }} </span>
                                </div> -->
                                <div class="uk-first-column">
                                    <span> <i class="uil-file-alt"></i> {{ totalTopics }} {{ pageContent('topics') }} </span>
                                </div>
                                <div>
                                    <span><i class="uil-youtube-alt"></i> {{ totalVideos }} {{ pageContent('videos') }}</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="uk-sticky-placeholder" style="height: 403px; margin: 0px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-content" v-if="showNoPermissionText">
            <div class="row">
                <div class="no-record">
                    <i class="uil uil-exclamation-triangle"></i>
                    <p class="mt-3">{{ pageContent('nopermission') }}</p>
                    <router-link to="/user/dashboard" class="btn btn-default transition-3d-hover">
                        {{ pageContent('backtocourse') }}
                    </router-link>
                </div>
            </div> 
        </div>
    </fragment>
</template>


<script>
import 'bootstrap';
import RatingForm from '../Course/RatingForm'
import Curriculum from '../Course/Curriculum'
import RatingList from '../Course/RatingList'
import RatingStar from '../Course/RatingStar'
import ReviewSummary from './ReviewSummary'
import Translation from '../../../public/translation.json'

export default {
    components: {Curriculum, RatingForm, RatingList, RatingStar, ReviewSummary},
    data(){
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',  
            courseData: [],
            topicData: [],
            courseId: 0,
            dirPath: '',
            ratings: [],
            assignemntData: [],
            assignmentPath: '',
            avgRatings: 0,
            totalTopics: 0,
            totalVideos: 0,
            showNoPermissionText: false,
            tutorData: []
        }
    }, 
    methods: {
        button(btn) {
            return Translation.button[this.defaultLang][btn]
        },
        fileUpload($event){ 
            let fileData = document.querySelector('#file_upload_'+$event.target.dataset.id).files[0]
            let formData = new FormData();
            formData.append("assignment_solved_copy", fileData);
            formData.append("assignment_id", $event.target.dataset.id);
           //console.log($event)
            this.$http.post(`account/user/course-assignment`, formData)
            .then(res=>{
                if(res.data.status === true) {
                    document.querySelector('#file_upload_'+$event.target.dataset.id).value = '';
                    this.$swal({
                        icon: 'success',
                        title: 'Success',
                        text: res.data.message,
                        timer: 3000
                    });
                    this.getCourseData();
                }
                else {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops',
                        text: res.data.error,
                        timer: 3000
                    });
                }
                document.querySelector('#file_upload_'+$event.target.dataset.id).value = '';
            })
            .catch(err => {
                console.log(err.response)
                this.$swal({
                    icon: 'error',
                    title: 'Oops',
                    text: err.response.data.error,
                });
            })
        },
        openUploadWindow(id){
            document.querySelector('#file_upload_'+id).click()
        },
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        addToFavourite(courseId, el) {
            let targetEl = el.target,
                params = {
                    course_id: courseId
                }
            this.$http.post(`account/user/favourite-course`, params)
            .then(res=>{
                if(res.data.status === true) {
                    if(res.data.message === 'Removed') {
                        targetEl.classList.remove('icon-material-outline-favorite')
                        targetEl.classList.add('icon-material-outline-favorite-border')
                    }
                    else {
                        targetEl.classList.add('icon-material-outline-favorite')
                        targetEl.classList.remove('icon-material-outline-favorite-border')
                    }
                }
            })
            .catch(err => {
                console.log(err.response)
            })
        },
        getCourseData() {
            this.$http.get(`account/user/${this.courseId}/single-course`).then(res=> {
                if(res.data.status === true) {
                    this.courseData = res.data.data
                    this.avgRatings = res.data.avg_ratings
                    this.ratings = res.data.ratings
                    this.totalTopics = res.data.total_topics
                    this.topicData = res.data.topics
                    this.totalVideos = res.data.total_videos
                    this.assignemntData = res.data.assignements
                    this.assignmentPath = res.data.assignment_path
                    this.tutorData = res.data.tutor_profile

                    if(res.data.file_path)
                        this.dirPath = res.data.file_path
                }
            })
            .catch(err=> {
                console.log(err.response.statusText)
                if(err.response.status === 422) {
                    this.courseData = []
                    this.showNoPermissionText = true
                }
                else if(err.response.status === 404) {
                    this.$router.push({name: '404'})
                }
            })
        },
        talkify(){
            talkify.config.debug = true;
            talkify.config.useSsml = true;
            talkify.config.remoteService.active = true;
            talkify.config.keyboardCommands.enabled = true;
            talkify.config.voiceCommands.enabled = true;

            talkify.config.remoteService.speechBaseUrl = '/api/speech/v1';
            talkify.config.remoteService.languageBaseUrl = '/api/language/v1';

            talkify.config.remoteService.apiKey = '2dda9146-9f0f-4ddd-a97c-59de9cc5c2a4';
        
            talkify.config.remoteService.host = 'https://talkify.net';

            talkify.config.ui.audioControls.enabled = true; //<-- Disable to get the browser built in audio controls
            talkify.config.ui.audioControls.container = document.getElementById("player-and-voices");
            talkify.config.ui.audioControls.voicepicker = {
                enabled: true,
                filter: {
                    byClass: [],
                    byCulture: ["en-EN", "fr-FR"],
                    byLanguage: ["English", "Spanish"]
                }
            };
            talkify.selectionActivator
                    .withTextHighlighting()
                    .withEnhancedVisibility()
                    .activate();
        }
    },
    created: function(){
        this.courseId = Number(this.$route.params.id)
        this.getCourseData();
        this.talkify();
    },
    watch: {
        "$route.params.id"() {
            this.courseId = Number(this.$route.params.id)
        }
    }
}
</script>

<style scoped>
.tutor-profile-img{float: left;margin: 0 14px 6px 0;max-width: 150px;}
.btn-default-outline{border: 1px solid;}
.wishlist_section{display: inline-block; float: right;}
</style>